/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames'
import Link from 'next/link'
import React, { useCallback } from 'react'

export interface DefaultLinkButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
  href?: string
  className?: string
  small?: boolean
  loading?: boolean
  disabled?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  'data-testid'?: string
}

const DefaultLinkButton = ({
  children,
  href,
  className,
  loading = false,
  disabled = false,
  small = false,
  leftIcon,
  rightIcon,
  ...rest
}: DefaultLinkButtonProps) => {
  const handleLinkClick = useCallback(
    (e: any) => {
      if (disabled || loading) {
        e.preventDefault()
      } else {
        rest.onClick?.(e)
      }
    },
    [disabled, loading, rest],
  )
  return (
    <Link
      href={href || ''}
      {...rest}
    >
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <a
        onClick={handleLinkClick}
        role="button"
        data-testid={rest?.['data-testid']}
        className={classNames(
          {
            'cursor-not-allowed': disabled,
            'cursor-progress': loading,
            'bg-neutral-800 text-white hover:text-white hover:bg-neutral-700':
              !loading && !disabled,
            'bg-neutral-400 text-neutral-100': loading || disabled,
            'hover:text-white': disabled,
          },
          small ? 'px-2 py-1' : 'px-4 py-2',
          'inline-flex items-center justify-center gap-3 rounded-md border border-transparent text-sm font-normal shadow-sm focus:outline-none focus:ring-2 focus:ring-neutral-600 focus:ring-offset-2',
          className,
        )}
      >
        {loading && (
          <svg
            className="pointer-events-none -ml-1 h-5 w-5 animate-spin text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}
        {!loading && leftIcon}
        {children}
        {rightIcon}
      </a>
    </Link>
  )
}

export default DefaultLinkButton
