import DefaultButton, { DefaultButtonProps } from './DefaultButton'
import classNames from 'classnames'

export interface NormalButtonProps extends DefaultButtonProps {
  buttonType?: 'primary' | 'danger' | 'outlined' | 'icon'
}
const NormalButton = ({
  buttonType = 'primary',
  className,
  ...rest
}: NormalButtonProps) => {
  if (buttonType === 'danger') {
    return (
      <DefaultButton
        {...rest}
        className={classNames(
          '!bg-red-500 !hover:bg-red-600 !focus:bg-red-600 !focus:ring-2 focus:ring-red-600',
          className,
        )}
      />
    )
  }
  if (buttonType === 'outlined') {
    return (
      <DefaultButton
        {...rest}
        className={classNames(
          '!bg-white !text-neutral-900 ring-1 ring-neutral-600',
          className,
        )}
      />
    )
  }
  if (buttonType === 'icon') {
    return (
      <DefaultButton
        {...rest}
        className={classNames(
          '!bg-light-grey ring-[0.5px] ring-dark-grey',
          className,
        )}
      />
    )
  }
  return (
    <DefaultButton
      {...rest}
      className={className}
    />
  )
}
export default NormalButton
