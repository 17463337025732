import classNames from 'classnames'
import NextLink from 'next/link'
import React from 'react'

export interface ButtonLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
  href: string
  className?: string
  disabled?: boolean
  'data-testid'?: string
}

const Link = ({ children, className, href, ...rest }: ButtonLinkProps) => (
  <NextLink
    href={href}
    {...rest}
  >
    <a
      className={classNames(
        'font-medium text-indigo-600 hover:text-indigo-500',
        className,
      )}
      data-testid={rest?.['data-testid']}
    >
      {children}
    </a>
  </NextLink>
)

export default Link
