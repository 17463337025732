import NormalButton, { NormalButtonProps } from './NormalButton'
import LinkButton, { LinkButtonProps } from './LinkButton'

export type ButtonProps = NormalButtonProps & LinkButtonProps
const Button = ({ href, ...rest }: ButtonProps) => {
  if (href) {
    return (
      <LinkButton
        href={href}
        {...rest}
      />
    )
  }
  return <NormalButton {...rest} />
}

export default Button
