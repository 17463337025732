import { Menu, Transition } from '@headlessui/react'
import { Fragment, useCallback } from 'react'
import { useUser, auth } from '@coding4tomorrow/c4t-next-core'
import Avatar from '@/Avatar'
import classNames from 'classnames'
import Link from 'next/link'

const AccountDropdown = () => {
  const { user, mutate } = useUser()

  const handleLogout = useCallback(() => {
    auth.clear()
    mutate(null)
  }, [mutate])

  if (!user) {
    return null
  }

  const userInitials = `${user?.firstName?.[0] || ''}${
    user?.lastName?.[0] || ''
  }`

  return (
    <div className="flex">
      <div className="flex-shrink-0 flex items-center">
        <Menu
          as="div"
          className="relative inline-block text-left"
        >
          <div>
            <Menu.Button
              data-testid="navbar-dropdown-menu"
              className="inline-flex justify-center items-center w-full px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none"
            >
              <Avatar>{userInitials}</Avatar>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute z-40 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link href="/preferences">
                      <a
                        data-testid="setting"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        )}
                      >
                        Account settings
                      </a>
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={handleLogout}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm',
                      )}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}

export default AccountDropdown
