export const UserRoles = {
  user: 'user',
  moderator: 'moderator',
  projectAdmin: 'project-admin',
  admin: 'admin',
  superAdmin: 'super-admin',
}

export const isUser = (role: string) => role === UserRoles.user
export const isModerator = (role: string) => role === UserRoles.moderator
export const isProjectAdmin = (role: string) => role === UserRoles.projectAdmin
export const isAdmin = (role: string) => role === UserRoles.admin
export const isSuperAdmin = (role: string) => role === UserRoles.superAdmin

export const isSuperiorRole = (role: string) =>
  [UserRoles.projectAdmin, UserRoles.admin, UserRoles.superAdmin].includes(role)

export const isAdminOrModerator = (role: string) =>
  [UserRoles.moderator, UserRoles.admin].includes(role)
