import classNames from 'classnames'
import DefaultLinkButton, { DefaultLinkButtonProps } from './DefaultLinkButton'

export interface LinkButtonProps extends DefaultLinkButtonProps {
  buttonType?: 'primary' | 'danger' | 'outlined' | 'icon'
}
const LinkButton = ({
  buttonType = 'primary',
  className,
  ...rest
}: LinkButtonProps) => {
  if (buttonType === 'outlined') {
    return (
      <DefaultLinkButton
        {...rest}
        className={classNames(
          '!bg-white !text-neutral-900 ring-1 ring-neutral-600',
          className,
        )}
      />
    )
  }
  return (
    <DefaultLinkButton
      className={className}
      {...rest}
    />
  )
}

export default LinkButton
